
  import axios, { AxiosError } from 'axios';
  import { defineComponent, ref, onMounted, watch } from 'vue';
  import useAlert from "@/composables/Alert";
  import {
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampEmptyListFeedbackV2
  } from '@/components';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { useFilterMainStore } from '@/store/FilterMainStore';
  import { useCompanyStore } from '@/store/CompanyStore';
  import moment from 'moment';
  import SkeletonComponent from '@/components/SkeletonComponent.vue';
  import ModalCompetitorList, { ICompetitorTraining } from '@/views/Training/Components/ModalCompetitorList.vue';
  import ModalReportCard from '@/views/Training/Components/ModalReportCard.vue';
  
  export type TYPE_STATUS_OPTIONS = 'closed' | 'passed' | 'failed'
  
  export const STATUS_OPTIONS: {
    CLOSED: TYPE_STATUS_OPTIONS,
    PASSED: TYPE_STATUS_OPTIONS,
    FAILED: TYPE_STATUS_OPTIONS,
  } = {
    CLOSED: 'closed',
    PASSED: 'passed',
    FAILED: 'failed',
  }
  
  export interface ITrainingData {
    id: number;
    title: string;
    description: string;
    url: null | string;
    initial_date: string;
    end_date: string;
    points: number;
    competitor_trainings: { competitor: { name: string } }[];
    training_groups: { id: number | null, id_company: number | null, id_store: number | null, id_training: number | null }[];
    competitor_trainings_count: number;
    failed_count: number;
    passed_count: number
  }
  
  interface ITrainingResponse {
    data: {
      data: ITrainingData[]
      error: boolean,
      message: string,
      errorCode: string
    }
  }
  
  export default defineComponent({
    name: "NotificationsList",
    components: {
      CampHeader,
      CampTable,
      CampTableTd,
      CampTableTh,
      CampEmptyListFeedbackV2,
      SkeletonComponent,
      ModalCompetitorList,
      ModalReportCard
    },
    setup() {
      const { showTimeAlert } = useAlert()
      const loaderStore = useLoaderStore()
      const filterMainStore = useFilterMainStore()
      const mediaModalToggle = ref(false)
      const mediaModalURL = ref("")
      // Company
      const companyStore = useCompanyStore()
      // Training
      const trainingList = ref<ITrainingData[] | null>(null)
      const trainingListRender = ref<any[] | null>(null)
      const keywordSearch = ref("")
      const isLoading = ref(false)
      const trainingInfo = ref<ITrainingData | null>(null)
      const competitorTrainingInfo = ref<ICompetitorTraining | null>(null)
      // Modal Competitor List
      const competitorsModalToggle = ref(false)
      const competitorListModalType = ref<TYPE_STATUS_OPTIONS>("passed")
      // Modal Report Card
      const reportCardModalToggle = ref(false)
  
      // Functions
      /** Fetch */
      async function postTrainingList(idJobAssignments, idJob, action) {
        // if(!companyStore.getId)
        //   return
        keywordSearch.value = ""
        try {
          let body = {
            id_company: companyStore.getId,
            id_jobs: idJob,
            id_jobsAssignments: idJobAssignments,
            action: action
          }
          isLoading.value = true
          await axios.post(`/api/postJobAssignments`, body)
          getTrainingList()
        } catch (error) {
          getTrainingList()
          if(error instanceof AxiosError) {
            if(error.response) {
              showTimeAlert(error.response?.data.message, "error")
            }else {
              showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
            }
          } else {
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          }
        } finally {
          loaderStore.close()
          isLoading.value = false
        }
      }

      async function getTrainingList() {
        // if(!companyStore.getId)
        //   return
        keywordSearch.value = ""
        try {
          isLoading.value = true
          const response = await axios.get(`/api/getJobAssignments?id_company=${companyStore.getId}`)
          const { data } = response.data
          trainingList.value = [ ...data ]
          trainingListRender.value = [ ...data ]
        } catch (error) {
          if(error instanceof AxiosError) {
            if(error.response) {
              showTimeAlert(error.response?.data.message, "error")
            }else {
              showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
            }
          } else {
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          }
        } finally {
          loaderStore.close()
          isLoading.value = false
        }
      }
  
      function handleFilterObjectsByKeyword(): boolean {
        if(!trainingList.value)
          return true
        trainingListRender.value = trainingList.value.map(el => ({ ...el, checked: false })).filter((item) => {
          for (const key in item) {
            if (key !== 'checked') {
              const propValue = item[key];
              if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
                return true;
              }
              if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
                return true;
              }
            }
          }
          return false;
        });
        return false
      }
  
      const handlePrintPoints = (value: number) => {
        let print: string | number = ''
        if (value > 0)
          print = `+ ${value}pt`
        else
          print = `${value}pt`
        if(Math.abs(value) > 1)
          print = `${print}s`
        return print
      }
  
      const handlePrintPointsStyle = (value: number) => {
        if(value === 0)
          return 'var(--cor_texto_1) !important'
        else if (value > 0)
          return 'var(--cor_indicadores_1) !important'
        else
          return 'var(--cor_indicadores_4) !important'
      }
  
      const handlePrintTrainingGroups1 = (array: { id: number | null; id_company: number | null; id_store: number | null; id_training: number | null; }[] ) => {
        if(Array.isArray(array)) {
          if(!array.length) {
            return 'Competidores'
          }
          if(array.some(el => el.id_company)) {
            return 'Companhias'
          }
          if(array.some(el => el.id_store)) {
            return 'Lojas'
          }
        }
      }
  
      const handlePrintTrainingGroups2 = (obj: ITrainingData, feedback = "--") => obj.competitor_trainings_count ? obj.competitor_trainings_count : feedback
  
      /** Handle Filter Modal */
      function handleInitFilterModal() {
        if(!companyStore.getId) {
          filterMainStore.hideModal()
          filterMainStore.showModal()
        }
      }
  
      /** Handle Open Media Modal */
      const handleToggleMediaModal = (url: string | null) => {
        mediaModalToggle.value = !mediaModalToggle.value
        mediaModalURL.value = url ? url : ''
      }
  
      /** Handle Open Competitor List Modal */
      const handlePassedCompetitorsModal = (training: ITrainingData) => {
        trainingInfo.value = training
        competitorListModalType.value = STATUS_OPTIONS.PASSED
        competitorsModalToggle.value = !competitorsModalToggle.value
      }
  
      const handleFailedCompetitorsModal = (training: ITrainingData) => {
        trainingInfo.value = training
        competitorListModalType.value = STATUS_OPTIONS.FAILED
        competitorsModalToggle.value = !competitorsModalToggle.value
      }
  
      /** Handle Open Report Card Modal */
      const handleReportCardModal = (value: ICompetitorTraining) => {
        reportCardModalToggle.value = !reportCardModalToggle.value
        competitorTrainingInfo.value = value
      }
  
      // Life Cycles
      onMounted(() => {
        getTrainingList()
        handleInitFilterModal()
      })
  
      watch(() => { companyStore.getId }, () => getTrainingList(), { deep: true })
  
      watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())
  
      return {
        isLoading,
        keywordSearch,
        companyStore,
        trainingList,
        trainingListRender,
        handleFilterObjectsByKeyword,
        moment,
        mediaModalToggle,
        mediaModalURL,
        handleToggleMediaModal,
        handlePrintPoints,
        handlePrintPointsStyle,
        handlePrintTrainingGroups1,
        handlePrintTrainingGroups2,
        handlePassedCompetitorsModal,
        handleFailedCompetitorsModal,
        competitorsModalToggle,
        competitorListModalType,
        handleReportCardModal,
        reportCardModalToggle,
        trainingInfo,
        competitorTrainingInfo,
        postTrainingList
      }
    }
  })
  